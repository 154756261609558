import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Grid, Paper } from '@mui/material';

const CultureDay = () => {
  const [nextFourthWednesday, setNextFourthWednesday] = useState(null);
  const [countdown, setCountdown] = useState('');
  const [milliseconds, setMilliseconds] = useState(0);

  // 넷째 주 수요일 날짜 계산 함수
  const calculateFourthWednesday = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();

    // 해당 달의 첫 번째 날과 요일 설정
    const firstDayOfMonth = new Date(year, month, 1);
    const firstWednesday = new Date(
      year,
      month,
      firstDayOfMonth.getDate() + ((3 - firstDayOfMonth.getDay() + 7) % 7)
    );

    // 넷째 주 수요일 날짜 계산
    const fourthWednesday = new Date(firstWednesday);
    fourthWednesday.setDate(firstWednesday.getDate() + 21);

    // 넷째 주 수요일이 오늘 날짜보다 이전이면, 다음 달의 넷째 주 수요일을 계산
    if (fourthWednesday <= today) {
      const nextMonth = new Date(year, month + 1, 1);
      return calculateFourthWednesdayForNextMonth(nextMonth); // 재귀 호출 수정
    }

    return fourthWednesday;
  };

  // 다음 달의 넷째 주 수요일 날짜 계산
  const calculateFourthWednesdayForNextMonth = (nextMonth) => {
    const year = nextMonth.getFullYear();
    const month = nextMonth.getMonth();

    const firstDayOfNextMonth = new Date(year, month, 1);
    const firstWednesdayNextMonth = new Date(
      year,
      month,
      firstDayOfNextMonth.getDate() + ((3 - firstDayOfNextMonth.getDay() + 7) % 7)
    );

    const fourthWednesdayNextMonth = new Date(firstWednesdayNextMonth);
    fourthWednesdayNextMonth.setDate(firstWednesdayNextMonth.getDate() + 21);

    return fourthWednesdayNextMonth;
  };

  // 카운트다운 업데이트 함수
  const updateCountdown = () => {
    if (!nextFourthWednesday) return;

    const now = new Date();
    const timeDifference = nextFourthWednesday - now;

    if (timeDifference <= 0) {
      setCountdown('D-day!');
      setMilliseconds(0); // 밀리초를 0으로 설정
      return;
    }

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
    const seconds = Math.floor((timeDifference / 1000) % 60);
    const ms = (timeDifference % 1000 /100 ).toFixed(0); // 밀리초 구하기
  

    setCountdown(`${days}일 ${hours}시간 ${minutes}분 ${seconds}.${ms}초`);
    setMilliseconds(ms);
  };

  useEffect(() => {
    // 넷째 주 수요일 날짜 계산 후 상태 업데이트
    const fourthWednesday = calculateFourthWednesday();
    setNextFourthWednesday(fourthWednesday);
  }, []); // 최초 한 번만 실행하여 넷째 주 수요일 날짜 계산

  useEffect(() => {
    if (!nextFourthWednesday) return;

    // 카운트다운을 1초마다 업데이트하도록 설정
    const countdownInterval = setInterval(updateCountdown, 100);

    // 컴포넌트 언마운트 시 인터벌을 정리
    return () => clearInterval(countdownInterval);
  }, [nextFourthWednesday]); // nextFourthWednesday가 변경될 때마다 새로 설정

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper elevation={3} style={{ padding: '1.5em', minHeight: '10em' }}>
          <Typography
            variant="h5"
            component="div"
            gutterBottom
            style={{ textAlign: 'center', minHeight: '2em' }}
          >
            🎊 문화의 날
          </Typography>

          {nextFourthWednesday ? (
            <>
              <Card variant="outlined" style={{ boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.1)'}}>
                <CardContent style={{padding:"16px"}}>
                  <Typography variant="h4" component="div" align="center" color="black">
                    {countdown}
                  </Typography>
                </CardContent>
              </Card>
            </>
          ) : (
            <Typography variant="subtitle1" style={{ textAlign: 'center' }}>
              계산 중...
            </Typography>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default CultureDay;