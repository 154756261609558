import React from 'react';
import { Paper, Card, CardContent, Typography, Grid } from '@mui/material';

const Dday = ({ events }) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0); // 오늘 날짜 시간 초기화
  const people = ['이종모', '손진혁', '하재천', '이현서', '강보영', '조현주', '원유진', '문영석', '김동희'];

  // 공휴일 리스트 예시
  const holidays = [
    '2024-01-01', // 예시 공휴일
    '2024-02-10',
    // 더 많은 공휴일 추가 가능
  ];

  // 공휴일을 제외한 이벤트 기간 계산 함수
  const getEventDurationExcludingHolidays = (start, end) => {
    let duration = 0;
    const current = new Date(start);

    while (current <= end) {
      const dateString = current.toISOString().split('T')[0];
      const isWeekend = current.getDay() === 0 || current.getDay() === 6;
      if (!isWeekend && !holidays.includes(dateString)) {
        duration += 1; // 공휴일 및 주말이 아니면 일수 추가
      }
      current.setDate(current.getDate() + 1);
    }
    return duration;
  };

  // 각 사람의 가장 가까운 연차/반차 이벤트를 찾는 로직
  const getClosestAnniversary = (person) => {
    const personEvents = events.filter(event =>
      event.summary.includes(person) &&
      (event.summary.includes('연차') || event.summary.includes('반차'))
    );

    if (personEvents.length === 0) return { type: '없음', daysUntilAnniversary: '없음', nextAnniversary: null, duration: 0 };

    const upcomingAnniversaries = personEvents
      .map(event => {
        const anniversary = new Date(event.startDate);
        anniversary.setFullYear(today.getFullYear());

        if (anniversary <= today) {
          anniversary.setFullYear(today.getFullYear() + 1);
        }

        const daysUntilAnniversary = Math.ceil((anniversary - today) / (1000 * 60 * 60 * 24));
        const type = event.summary.includes('연차') ? '연차' : '반차';
        const eventDuration = getEventDurationExcludingHolidays(event.startDate, event.endDate);

        return { type, nextAnniversary: anniversary, daysUntilAnniversary, eventDuration };
      })
      .filter(event => event.nextAnniversary > today && event.daysUntilAnniversary <= 100);

    if (upcomingAnniversaries.length === 0) return { type: '없음', daysUntilAnniversary: '없음', nextAnniversary: null, duration: 0 };

    return upcomingAnniversaries.reduce((closest, current) =>
      closest.daysUntilAnniversary < current.daysUntilAnniversary ? closest : current
    );
  };

  const peopleAnniversaries = people.map(person => {
    const { type, nextAnniversary, daysUntilAnniversary, eventDuration } = getClosestAnniversary(person);
    const weekday = nextAnniversary ? nextAnniversary.toLocaleDateString('ko-KR', { weekday: 'short' }) : '';
    const formattedDate = nextAnniversary ? `${nextAnniversary.toLocaleDateString()} (${weekday})` : '';

    return { person, type, nextAnniversary, daysUntilAnniversary, formattedDate, eventDuration };
  });

  const sortedPeopleAnniversaries = peopleAnniversaries.sort((a, b) => {
    if (a.daysUntilAnniversary !== '없음' && b.daysUntilAnniversary !== '없음') {
      return a.daysUntilAnniversary - b.daysUntilAnniversary;
    } else if (a.daysUntilAnniversary === '없음' && b.daysUntilAnniversary === '없음') {
      return 0;
    }
    return a.daysUntilAnniversary === '없음' ? 1 : -1;
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper elevation={3} style={{ padding: '1.5em', marginBottom: '1em' }}>
          <Typography variant="h5" component="div" gutterBottom style={{ textAlign: 'center', minHeight: '2em' }}>
            ⛱️ 행복의 순서
          </Typography>

          {sortedPeopleAnniversaries.map(({ person, type, nextAnniversary, daysUntilAnniversary, formattedDate, eventDuration }) => (
            <Card variant="outlined" key={person} style={{ marginBottom: '10px'}}>
              <CardContent style={{padding:'10px'}}>
                <Typography variant="subtitle1" style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span>⛱️ {person} {daysUntilAnniversary !== '없음' ? `${type} [${eventDuration}일]` : ""}</span>
                  <span>
                    {daysUntilAnniversary !== '없음'
                      ? `⏱️D-${daysUntilAnniversary}`
                      : '없음'}
                  </span>
                </Typography>
                <Typography align='right' fontSize={"0.5em"}>
                     {`${formattedDate}`}
                </Typography>

              </CardContent>
            </Card>
          ))}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Dday;