import React, { useEffect, useState } from 'react';
import { Paper, Card, CardContent, Typography, Grid } from '@mui/material';
import IcalExpander from 'ical-expander';

const MeetingRoom = () => {
  const [events, setEvents] = useState([]);
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Reset time to midnight for today’s date comparison

  // 미팅룸 데이터를 가져오는 함수
  const fetchMeetingRoomData = async () => {
    try {
      const response = await fetch(
        'https://calendar.kakaowork.com/ical/calendars/ea0990e3d54826d78281b5d3d320eecb/private/2f629bbc65cb724d870b10ad04454a97/basic.ics'
      );
      const text = await response.text();
      const icalExpander = new IcalExpander({ ics: text, maxIterations: 1000 });
      const { events: vevents } = icalExpander.between(today, new Date(today.getTime() + 24 * 60 * 60 * 1000));

      const eventsArray = vevents.map((event) => ({
        summary: event.summary,
        startTime: event.startDate.toJSDate(),
        endTime: event.endDate.toJSDate(),
      }));

      // startTime을 기준으로 오름차순 정렬
      eventsArray.sort((a, b) => a.startTime - b.startTime);

      setEvents(eventsArray);
      console.log(eventsArray);

    } catch (error) {
      console.error('Failed to fetch meeting room data:', error);
    }
  };

  useEffect(() => {
    // 데이터 초기 로딩
    fetchMeetingRoomData();

    // 30초마다 fetchMeetingRoomData 호출
    const interval = setInterval(fetchMeetingRoomData, 30000);

    // 컴포넌트 언마운트 시 interval 정리
    return () => clearInterval(interval);
  }, []); // 빈 배열을 사용하여 한 번만 실행하고 30초마다 갱신

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper elevation={3} style={{ padding: '1.5em', marginBottom: '1em' }}>
          <Typography variant="h5" component="div" gutterBottom style={{ textAlign: 'center', minHeight: '2em' }}>
            🔍 회의실 자리가..?
          </Typography>
          {events.length === 0 ? (
            <Typography variant="subtitle1" style={{ textAlign: 'center' }}>
              ❌ 오늘 예약된 회의실이 없습니다
            </Typography>
          ) : (
            events.map((event, index) => (
              <Card variant="outlined" key={index} style={{ marginBottom: '10px' }}>
                <CardContent>
                  <Typography variant="subtitle1">
                    <span>📚 {event.summary}</span>
                  </Typography>
                  <Typography align='right' fontSize={"0.8em"}>
                    { "🕓 [" + event.startTime.toLocaleTimeString('ko-KR', { hour: '2-digit', minute: '2-digit' })} - {event.endTime.toLocaleTimeString('ko-KR', { hour: '2-digit', minute: '2-digit' }) + "]"}
                  </Typography>
                </CardContent>
              </Card>
            ))
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default MeetingRoom;