// Calendar.js
import React, { useEffect, useState } from 'react';
import { Paper, Card, CardContent, Typography, Grid } from '@mui/material';

const Calendar = ({ startDate, endDate, events, title }) => {
  const [filteredEvents, setFilteredEvents] = useState([]);

  useEffect(() => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const filtered = events.filter(event => {
      const eventDate = new Date(event.startDate);
      return eventDate >= start && eventDate <= end;
    });
    setFilteredEvents(filtered);
  }, [startDate, endDate, events]);

  return (
    <Paper elevation={3} style={{ padding: '1.5em', marginBottom: '1em' }}>
      <Typography variant="h5" component="div" gutterBottom style={{ textAlign: 'center', minHeight: '2em' }}>
        {title}
      </Typography>
      
      {filteredEvents.length === 0 ? (
        <Card variant="outlined">
          <CardContent>
            <Typography variant="subtitle1" style={{ textAlign: 'center' }}>
              없음
            </Typography>
          </CardContent>
        </Card>
      ) : (
        filteredEvents.map((event, index) => {
          const eventDate = new Date(event.startDate);
          const weekday = eventDate.toLocaleDateString('ko-KR', { weekday: 'short' }); // 요일 구하기
          const formattedDate = `${eventDate.toLocaleDateString()} (${weekday})`;

          return (
            <Card variant="outlined" key={index} style={{ marginBottom: '10px' }}>
              <CardContent>
                <Typography variant="subtitle1" >
                  <span>⛱️ {event.summary}</span>
                </Typography>
                <Typography align='right' fontSize={"0.8em"}>
                     {"🕓 "+formattedDate}
                </Typography>
              </CardContent>
            </Card>
          );
        })
      )}
    </Paper>
  );
};

export default Calendar;