import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider, createTheme } from '@mui/material/styles';

// 폰트 스타일을 포함한 테마 설정
const theme = createTheme({
  typography: {
    fontFamily: '"Gothic A1", sans-serif', // 원하는 폰트 패밀리로 변경하세요
    fontWeightLight: 500,
    fontWeightRegular: 500,
  },
 cardcomponent :{
  padding : "16px",
 }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

reportWebVitals();