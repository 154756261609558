import React, { useEffect, useState } from 'react';
import Calendar from './compo/calendar';
import Dday from './compo/d-day';
import MeetingRoom from './compo/MeetingRoom';
import CultureDay from './compo/CultureDay';
import IcalExpander from 'ical-expander';
import { Container, Grid, Typography } from '@mui/material';

function App() {
  const [events, setEvents] = useState([]);

  // 숫자로 받은 인수에 따라 주의 날짜 범위를 계산하는 함수
  const getWeekDates = (weekOffset) => {
    const today = new Date();
    // 현재 날짜를 기준으로 이번 주 시작일 (월요일)
    const startOfWeek = today.getDate() - today.getDay() + 1; // 월요일을 시작일로 설정
    const firstDayOfWeek = new Date(today.setDate(startOfWeek));
    firstDayOfWeek.setDate(firstDayOfWeek.getDate() + (weekOffset * 7)); // weekOffset에 따라 이동

    const lastDayOfWeek = new Date(firstDayOfWeek);
    lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6); // 주의 마지막 날짜 (일요일)

    return {
      startDate: firstDayOfWeek.toISOString().split('T')[0], // ISO 날짜 형식으로 변환
      endDate: lastDayOfWeek.toISOString().split('T')[0],
      title: weekOffset === 0 ? '이번 주' : weekOffset === 1 ? '다음 주' : '다다음 주'
    };
  };

  useEffect(() => {
    const fetchIcalData = async () => {
      try {
        const response = await fetch(
          'https://calendar.kakaowork.com/ical/calendars/5a28874b3fe956b686e2f0f7b076e4ae/private/929c0056aefc31d1b83ac0959af543d1/basic.ics'
        );
        const text = await response.text();
        const icalExpander = new IcalExpander({ ics: text, maxIterations: 1000 });
        const { events: vevents } = icalExpander.all();

        const eventsArray = vevents.map((event) => ({
          summary: event.summary,
          startDate: event.startDate.toJSDate(),
          endDate: event.endDate.toJSDate(),
          attendees: event.attendees ? event.attendees.map((attendee) => attendee.getFirstValue()) : []
        }));

        setEvents(eventsArray);
      } catch (error) {
        console.error('Failed to fetch iCal data:', error);
      }
    };

    fetchIcalData(); // 처음 한 번 데이터를 가져옴

    const intervalId = setInterval(() => {
      fetchIcalData(); // 1분마다 데이터 새로고침
    }, 60000/2); // 60000ms = 1분

    // 컴포넌트 언마운트 시 interval 정리
    return () => clearInterval(intervalId);
  }, []);

  return (
    <Grid container spacing={2}  sx={{px:10}}>
      <Grid item xs={120} md={12}>
        <Typography variant="h4" component="h1" style={{textAlign: 'center', margin : '0.5em'}}>
          R&D는 지금...
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <Dday events={events} />
      </Grid>
      <Grid item xs={12} md={4}>
        <Calendar 
          startDate={getWeekDates(0).startDate}
          endDate={getWeekDates(0).endDate}
          events={events}
          title="이번 주"
        />
        <Calendar
          startDate={getWeekDates(1).startDate}
          endDate={getWeekDates(1).endDate}
          events={events}
          title="다음 주"
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <MeetingRoom
        />
        <CultureDay/>
      </Grid>
    </Grid>
  );
}

export default App;